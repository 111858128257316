<template>
  <div>
    <div class="d-flex justify-center animate__animated animate__zoomIn blue--text heading">
      Diani Crystal Gardens
    </div>
    <v-row class="no-gutters mb-3">
      <v-col class="col-12  pa-4" style="border-right: solid 3px white;">
              <v-img class="img rounded lighten-4 my-1"
              contain
              :src="require(`../../assets/real_land_1.png`)"
              />
              <v-img class="img rounded lighten-4 my-1"
              contain
              :src="require(`../../assets/real_land_3.png`)"
              />
              <v-img class="img rounded lighten-4 my-1"
              contain
              :src="require(`../../assets/real_land_4.png`)"
              />
              <v-img class="img rounded lighten-4 my-1"
              contain
              :src="require(`../../assets/real_land_0.png`)"
              />
              <v-img class="img rounded lighten-4 my-1"
              contain
              :src="require(`../../assets/real_land_2.png`)"
              />
        <div>
          <span class="d-flex white align-center red rounded justimmfy-center white--text">
            <v-spacer />
              <span class="white--text">
                {{"350,000"}}
              </span>
            <v-spacer />
          </span>
        </div>
        <v-col class="col-12 px-4 text--bold" style="font-weight: 900;">
          <h3 class="d-flex align-center mr-2"> 
            <v-icon small class="red--text">
              mdi-pin
            </v-icon>
            Diani
          </h3>
          <h5 class="d-flex"> 
          <v-icon x-small class="green--text mx-1">
              mdi-circle
          </v-icon>
          {{"400M off tamarc."}} 
          </h5>
          <h5 class="d-flex"> 
          <v-icon x-small class="green--text mx-1">
              mdi-circle
          </v-icon>
          {{"1KM from Technical University of Mombasa."}} 
          </h5>
          <h5 class="d-flex"> 
          <v-icon x-small class="green--text mx-1">
              mdi-circle
          </v-icon>
          {{"15Min Drive to Ukunda Airstrip."}} 
          </h5>
          <h5 class="d-flex"> 
          <v-icon x-small class="green--text mx-1">
              mdi-circle
          </v-icon>
          {{"20Min Drive to Diani Beach."}} 
          </h5>
          <h5 class="d-flex"> 
          <v-icon x-small class="green--text mx-1">
              mdi-circle
          </v-icon>
          {{"Water and Electricity on-site."}} 
          </h5>
        </v-col>
        </v-col>
      </v-row>
      <v-row class="no-gutters mb-3 pt-4">
      <p class="heading blue--text text-center pt-4" style="width: 100%;">Got a Question?</p>
      <v-col class="col-12 d-flex justify-center align-center pa-4" style="border-right: solid 3px white;">
        Our team team will get back to through either of the following means, our default channel is through calls, but you may specify your preffered channel:
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <p class="sub-heading blue--text d-flex align-center">
          <v-icon class="blue rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          Phone Call
        </p> 
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <p class="sub-heading blue--text d-flex align-center">
          <v-icon class="blue rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          SMS
        </p>
      </v-col>
      <v-col class="col-12 ml-4 pl-4">
        <p class="sub-heading blue--text d-flex align-center">
          <v-icon class="blue rounded bold white--text mr-1" style="font-weigth: 800;">
            mdi-check
          </v-icon>
          Email
        </p>
      </v-col>
      <v-col class="col-12 d-flex justify-center">
        <v-form ref="form" class="" style="width: 90%;"
          v-model="valid"
          :lazy-validation="lazy">
          <p class="text--center"> 
            Enter your enquiry below
          </p>
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="name"
          v-model="contact_data.name"
          label="name"
          type="name"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="email"
          v-model="contact_data.email"
          label="email"
          type="name"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="phone number"
          v-model="contact_data.phone_number"
          label="phone number"
          type="number"
          outlined
          clearable />
          <v-text-field
          :rules="rules.requiredRule"
          placeholder="subject"
          v-model="contact_data.subject"
          label="subject"
          type="name"
          outlined
          clearable />
          <v-textarea
          :rules="rules.requiredRule"
          placeholder="message"
          v-model="contact_data.message"
          label="message"
          type="name"
          outlined
          clearable />
          <div  class="d-flex justify-end">
            <v-btn class="success" @click="enquire" :loading="enquire_loading" :disabled="!valid"> send </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
  name: 'View',
  data(){
    return{
      contact_data: {
        subject: '#Diani Crystal Garden'
      },
      enquire_loading: false,
      book_data: {},
      book_loading: false,
      valid: true,
      lazy: false,
      rules: {
        requiredRule: [
          v => !!v || 'This field is required!',
        ]
      } 
    }
  },
  methods: {
    ...mapActions(['toogleAlertBox']),
    async enquire(){
      try{
        console.log(this.contact_data)
        this.enquire_loading = true
        const response = await
        axios.post(process.env.VUE_APP_API + '/api/enquire', this.contact_data).then((response) => {
          console.log(response)
          const alert_box_info = {
            status: true,
            information: 'Enquiry recorded successfully, we shall get in touch as soon as possible.',
            code: 'success'
          }
          this.toogleAlertBox(alert_box_info)
          this.contact_data = {
            subject: '#Diani Crystal Garden'
          }
          this.enquire_loading = false
        })
        console.log(response)
      } catch(e){
        console.log(e)
      }
    },
    async book(){
      try{
        console.log(this.book_data)
        this.book_loading = true
        const response = await
        axios.post('https://api.adilirealestate.com/api/book', this.book_data).then((response) => {
          console.log(response)
          const alert_box_info = {
            status: true,
            information: 'Site visit recorded successfully, we shall get in touch as soon as possible.',
            code: 'success'
          }
          this.toogleAlertBox(alert_box_info)
          this.book_data = {}
          this.book_loading = false
        })
        console.log(response)
      } catch(e){
        console.log(e)
      }
    }
  }
}
</script>
